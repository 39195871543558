import { createContext, useContext, useEffect, useMemo, useState, useLayoutEffect, useRef } from "react";
import Lenis from '@studio-freight/lenis'
// Import the necessary function for preloading images

const PageContext = createContext({
    lenis: null,
})

export const PageProvider = ({ children }) => {
    const [lenis, setLenis] = useState();

    const reqIdRef = useRef();

    useLayoutEffect(() => {
        const lenis = new Lenis({
            // duration: 1.2,
            // easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)),
            ease: "power1.out",
            orientation: 'vertical',
            gestureOrientation: 'vertical',
            // sm: true,
            // smoothTouch: false,
            // touchMultiplier: 2,
            lerp: 0.02,
            smoothWheel: true
        });


        // let lastHeight = 10;
        // let hideNav = false;

        // lenis.on('scroll', ({ scroll }) => {
        //     if (lastHeight < scroll && scroll > 200 && !hideNav) {
        //         document.body.classList.add("hideHeader");
        //         hideNav = true;
        //     }

        //     if (lastHeight >= scroll && scroll < 200 && hideNav) {
        //         document.body.classList.remove("hideHeader");
        //         hideNav = false;
        //     }

        // })


        setLenis(lenis);


        return () => {
            lenis.destroy();
            setLenis(null);
        }


    }, []);


    useEffect(() => {
        const animate = (time) => {
            lenis?.raf(time);
            reqIdRef.current = requestAnimationFrame(animate);
        }

        reqIdRef.current = requestAnimationFrame(animate);

        return () => cancelAnimationFrame(reqIdRef.current);
    }, [lenis]);


    const memoedValue = useMemo(
        () => ({
            lenis
        }), [lenis]

    );


    return (
        <PageContext.Provider value={memoedValue}>{children}</PageContext.Provider>
    )

}


export default function usePage() {
    return useContext(PageContext);
}