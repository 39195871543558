import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react'
import styled from 'styled-components';

const ProgressBar = () => {
    const [scrollPercentage, setScrollPercentage] = useState(1);

    useEffect(() => {
        const handleScroll = () => {
            const windowHeight = window.innerHeight;
            const documentHeight = document.documentElement.scrollHeight;
            const scrollY = window.scrollY;


            const scrollPercent = (scrollY / (documentHeight - windowHeight)) * 100;
            // console.log(scrollPercent)
            setScrollPercentage(scrollPercent);
        }

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll)
        }
    }, [])

    return (
        <ProgressContainer>
            <div className="progressBar" style={{ width: `${scrollPercentage}%` }}>

            </div>
        </ProgressContainer>
    )
}

const ProgressContainer = styled.div`
width: 100%;
height: 4px;
background: #fff;
position: fixed;
top: 0;
left: 0;
z-index: 99;

    .progressBar{
        height: 100%;
        background: #FF471E;
    }

`

export default ProgressBar