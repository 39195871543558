import img1 from "./assets/posters/1.webp";
import img2 from "./assets/posters/2.webp";
import img3 from "./assets/posters/3.webp";
import img4 from "./assets/posters/4.webp";
import img5 from "./assets/posters/5.webp";
import img6 from "./assets/posters/6.webp";
import img7 from "./assets/posters/7.webp";
import img8 from "./assets/posters/8.webp";
import img9 from "./assets/posters/9.webp";
import img10 from "./assets/posters/10.webp";
import img11 from "./assets/posters/11.webp";
import img12 from "./assets/posters/12.webp";
import img13 from "./assets/posters/13.webp";
import img14 from "./assets/posters/14.webp";
import img15 from "./assets/posters/15.webp";
import img16 from "./assets/posters/16.webp";
import img17 from "./assets/posters/17.webp";
import img18 from "./assets/posters/18.webp";
import img19 from "./assets/posters/19.webp";
import img20 from "./assets/posters/20.webp";
import img21 from "./assets/posters/21.webp";
import img22 from "./assets/posters/22.webp";
import img23 from "./assets/posters/23.webp";
import img24 from "./assets/posters/24.webp";
import img25 from "./assets/posters/25.webp";
import img26 from "./assets/posters/26.webp";
import img27 from "./assets/posters/27.webp";
import img28 from "./assets/posters/28.webp";
import img29 from "./assets/posters/29.webp";
import img30 from "./assets/posters/30.webp";
import img31 from "./assets/posters/31.webp";
import img32 from "./assets/posters/32.webp";
import img33 from "./assets/posters/33.webp";
import img34 from "./assets/posters/34.webp";
import img35 from "./assets/posters/35.webp";
import img36 from "./assets/posters/36.webp";
import img37 from "./assets/posters/37.webp";
import img38 from "./assets/posters/38.webp";
import img39 from "./assets/posters/39.webp";
import img40 from "./assets/posters/40.webp";
import img41 from "./assets/posters/41.webp";
import img42 from "./assets/posters/42.webp";
import img43 from "./assets/posters/43.webp";
import img44 from "./assets/posters/44.webp";
import img45 from "./assets/posters/45.webp";
import img46 from "./assets/posters/46.webp";
import img47 from "./assets/posters/47.webp";
import img48 from "./assets/posters/48.webp";
import img49 from "./assets/posters/49.webp";
import img50 from "./assets/posters/50.webp";
import img51 from "./assets/posters/51.webp";
import img52 from "./assets/posters/52.webp";
import img53 from "./assets/posters/53.webp";
import img54 from "./assets/posters/54.webp";
import img55 from "./assets/posters/55.webp";
import img56 from "./assets/posters/56.webp";
import img57 from "./assets/posters/57.webp";
import img58 from "./assets/posters/58.webp";
import img59 from "./assets/posters/59.webp";
import img60 from "./assets/posters/60.webp";
import img61 from "./assets/posters/61.webp";
import img62 from "./assets/posters/62.webp";
import img63 from "./assets/posters/63.webp";
import img64 from "./assets/posters/64.webp";
import img65 from "./assets/posters/65.webp";
import img66 from "./assets/posters/66.webp";
import img67 from "./assets/posters/67.webp";
import img68 from "./assets/posters/68.webp";
import img69 from "./assets/posters/69.webp";
import img70 from "./assets/posters/70.webp";
import img71 from "./assets/posters/71.webp";
import img72 from "./assets/posters/72.webp";
import img73 from "./assets/posters/73.webp";
import img74 from "./assets/posters/74.webp";
import img75 from "./assets/posters/75.webp";
import img76 from "./assets/posters/76.webp";
import img77 from "./assets/posters/77.webp";
import img78 from "./assets/posters/78.webp";
import img79 from "./assets/posters/79.webp";
import img80 from "./assets/posters/80.webp";
import img81 from "./assets/posters/81.webp";
import img82 from "./assets/posters/82.webp";
import img83 from "./assets/posters/83.webp";
import img84 from "./assets/posters/84.webp";
import img85 from "./assets/posters/85.webp";
import img86 from "./assets/posters/86.webp";
import img87 from "./assets/posters/87.webp";
import img88 from "./assets/posters/88.webp";
import img89 from "./assets/posters/89.webp";
import img90 from "./assets/posters/90.webp";
import img91 from "./assets/posters/91.webp";
import img92 from "./assets/posters/92.webp";
import img93 from "./assets/posters/93.webp";
import img94 from "./assets/posters/94.webp";
import img95 from "./assets/posters/95.webp";
import img96 from "./assets/posters/96.webp";
import img97 from "./assets/posters/97.webp";
import img98 from "./assets/posters/98.webp";
import img99 from "./assets/posters/99.webp";
import img100 from "./assets/posters/100.webp";

// Design Work

import workImg1 from "./assets/CoachOptima223.webp";
import workImg2 from "./assets/InvestorZone.webp";
import workImg3 from "./assets/Certififdf.webp";
import workImg4 from "./assets/OmmuneProducts.webp";
import workImg5 from "./assets/TTXCRM.webp";
import workImg6 from "./assets/WPdigipro.webp";

import workImg7 from "./assets/work/AsusROGMockups222.webp";
import workImg8 from "./assets/work/CardFive.webp";
import workImg9 from "./assets/work/CardFour.webp";
import workImg10 from "./assets/work/CardThree.webp";
import workImg11 from "./assets/work/CardTwo.webp";
import workImg12 from "./assets/work/Devalaya.webp";
import workImg13 from "./assets/work/DevalayaKitchen.webp";
import workImg14 from "./assets/work/IFSC.webp";
import workImg15 from "./assets/work/Instagram_Checkout_1.webp";
import workImg16 from "./assets/work/ReserveTrees.webp";
import workImg17 from "./assets/work/RoundTable.webp";
import workImg18 from "./assets/work/SkullSalazar.webp";
import workImg19 from "./assets/work/WPHRM222.webp";
import workImg20 from "./assets/work/WarehousePirate.webp";
import workImg21 from "./assets/work/cryptoExchange.webp";
import workImg22 from "./assets/work/hoppingPlantassa.webp";
import workImg23 from "./assets/work/shivaTemplePhoto3.webp";
import workImg24 from "./assets/work/skillFive.webp";

const images = {
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  img9,
  img10,
  img11,
  img12,
  img13,
  img14,
  img15,
  img16,
  img17,
  img18,
  img19,
  img20,
  img21,
  img22,
  img23,
  img24,
  img25,
  img26,
  img27,
  img28,
  img29,
  img30,
  img31,
  img32,
  img33,
  img34,
  img35,
  img36,
  img37,
  img38,
  img39,
  img40,
  img41,
  img42,
  img43,
  img44,
  img45,
  img46,
  img47,
  img48,
  img49,
  img50,
  img51,
  img52,
  img53,
  img54,
  img55,
  img56,
  img57,
  img58,
  img59,
  img60,
  img61,
  img62,
  img63,
  img64,
  img65,
  img66,
  img67,
  img68,
  img69,
  img70,
  img71,
  img72,
  img73,
  img74,
  img75,
  img76,
  img77,
  img78,
  img79,
  img80,
  img81,
  img82,
  img83,
  img84,
  img85,
  img86,
  img87,
  img88,
  img89,
  img90,
  img91,
  img92,
  img93,
  img94,
  img95,
  img96,
  img97,
  img98,
  img99,
  img100,

  workImg1,
  workImg2,
  workImg3,
  workImg4,
  workImg5,
  workImg6,
  workImg7,
  workImg8,
  workImg9,
  workImg10,
  workImg11,
  workImg12,
  workImg13,
  workImg14,
  workImg15,
  workImg16,
  workImg17,
  workImg18,
  workImg19,
  workImg20,
  workImg21,
  workImg22,
  workImg23,
  workImg24,
};

export default images;
