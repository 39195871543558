import React, { useLayoutEffect, useRef } from "react";
import styled from "styled-components";
import Creativity from "../assets/creativity.svg";
import Connection from "../assets/connection.svg";
import Gods from "../assets/gods.svg";
import gsap from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger);

function About() {

  let content = useRef(null);

  useLayoutEffect(() => {
    const tel = gsap.timeline();
    const image1 = content.children[0].children;
    const image2 = content.children[1].children;
    const image3 = content.children[2].children;



    tel
      .fromTo([image1], {
        opacity: 0,
        y: 400
      },
        {
          opacity: 1,
          y: 0,
          // stagger: 0.2,
          // duration: 0.5,
          ease: "power5.out",
          scrollTrigger: {
            trigger: "#about",
            start: "center bottom",
            end: "center center",
            scrub: 2,
          }
        })
      .fromTo([image2], {
        opacity: 0,
        y: 400
      },
        {
          opacity: 1,
          y: 0,
          // stagger: 0.2,
          // duration: 0.5,
          ease: "power5.out",
          scrollTrigger: {
            trigger: "#about",
            start: "center bottom",
            end: "center center",
            scrub: 2
          }
        })
      .fromTo([image3], {
        opacity: 0,
        y: 400
      },
        {
          opacity: 1,
          y: 0,
          // stagger: 0.2,
          // duration: 0.5,
          ease: "power5.out",
          scrollTrigger: {
            trigger: "#about",
            start: "center bottom",
            end: "center center",
            scrub: 2
          }
        })

  }, [])


  return (
    <AboutSection id="about">
      <div className="flex-about" ref={el => content = el}>
        <div className="mask">
          <img id="creative" src={Creativity} alt="Creativity is" />
        </div>
        <div className="mask">
          <img id="connect" src={Connection} alt="my connection" />
        </div>
        <div className="mask">
          <img id="gods" src={Gods} alt="to gods" />
        </div>
      </div>
    </AboutSection>
  );
}

const AboutSection = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .flex-about{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    margin: 40vh 0;
  }

  .mask{
    width: 100%;
    max-height: 100px;
    height: auto;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: transparent;
    margin-bottom: 5px;



    #creative{
      max-height: 85px;
    }
    #connect{
      max-height: 85px;
    }
    #gods{
      max-height: 85px;
    }
  }

  
`;

export default About;
