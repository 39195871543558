import React, { useState } from 'react'
import styled from 'styled-components'
import Web from "../assets/codeing.webp";
import Blend from "../assets/blender.webp";

import images from "../skillList"




function SkillSection() {




    const [isHovering1, setIsHovering1] = useState(false);
    const [isHovering2, setIsHovering2] = useState(false);
    const [isHovering3, setIsHovering3] = useState(false);
    const [isHovering4, setIsHovering4] = useState(false);

    const handleMouseEnter1 = () => {
        setIsHovering1(true);
    };

    const handleMouseLeave1 = () => {
        setIsHovering1(false);
    };

    const handleMouseEnter2 = () => {
        setIsHovering2(true);
    };

    const handleMouseLeave2 = () => {
        setIsHovering2(false);
    };

    const handleMouseEnter3 = () => {
        setIsHovering3(true);
    };

    const handleMouseLeave3 = () => {
        setIsHovering3(false);
    };

    const handleMouseEnter4 = () => {
        setIsHovering4(true);
    };

    const handleMouseLeave4 = () => {
        setIsHovering4(false);
    };


    return (
        <SkillsSection>
            <span className='sectionTitle'>What I Do</span>
            <div className="skillset">
                <div className="markskill" onMouseEnter={handleMouseEnter1}
                    onMouseLeave={handleMouseLeave1}>
                    <div className="skill">
                        <span>
                            UI Design
                        </span>
                    </div>
                    <div className="skillHover" style={{
                        opacity: isHovering1 ? '1' : '0',
                        height: isHovering1 ? "100%" : "0%"
                    }}>
                        <span>
                            UI Design
                        </span>
                    </div>
                </div>
                <div className="markskill" onMouseEnter={handleMouseEnter2}
                    onMouseLeave={handleMouseLeave2}>
                    <div className="skill">
                        <span>
                            Graphic designer
                        </span>
                    </div>
                    <div className="skillHover" style={{
                        opacity: isHovering2 ? '1' : '0',
                        height: isHovering2 ? "100%" : "0%"
                    }}>
                        <span>
                            Graphic designer
                        </span>
                    </div>
                </div>
                <div className="markskill" onMouseEnter={handleMouseEnter3}
                    onMouseLeave={handleMouseLeave3}>
                    <div className="skill">
                        <span>
                            Web development
                        </span>
                    </div>
                    <div className="skillHover" style={{
                        opacity: isHovering3 ? '1' : '0',
                        height: isHovering3 ? "100%" : "0%"
                    }}>
                        <span>
                            Web development
                        </span>
                    </div>
                </div>
                <div className="markskill" onMouseEnter={handleMouseEnter4}
                    onMouseLeave={handleMouseLeave4}>
                    <div className="skill">
                        <span>
                            3d Generalist
                        </span>
                    </div>
                    <div className="skillHover" style={{
                        opacity: isHovering4 ? '1' : '0',
                        height: isHovering4 ? "100%" : "0%"
                    }}>
                        <span>
                            3d Generalist
                        </span>
                    </div>
                </div>
                <img style={{
                    opacity: isHovering1 ? '1' : '0',
                    transform: isHovering1 ? "scale(1) translateY(0px) rotate(2deg)" : "scale(0.8) translateY(20px) rotate(-6deg)"
                }} className="imageFloat"
                    src={images.workImg1} alt="Examples of UI design"
                />
                <img style={{
                    opacity: isHovering2 ? '1' : '0',
                    transform: isHovering2 ? "scale(1) translateY(0px) rotate(2deg)" : "scale(0.8) translateY(20px) rotate(-6deg)"
                }} className="imageFloat"
                    src={images.img73}
                    alt="Examples of UI design"
                />
                <img style={{
                    opacity: isHovering3 ? '1' : '0',
                    transform: isHovering3 ? "scale(1) translateY(0px) rotate(2deg)" : "scale(0.8) translateY(20px) rotate(-6deg)"
                }} className="imageFloat"
                    src={Web}
                    alt="Examples of UI design"
                />
                <img style={{
                    opacity: isHovering4 ? '1' : '0',
                    transform: isHovering4 ? "scale(1) translateY(0px) rotate(2deg)" : "scale(0.8) translateY(20px) rotate(-6deg)"
                }} className="imageFloat" src={Blend} alt="Examples of UI design" />
            </div>
        </SkillsSection>
    )
}

const SkillsSection = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;
    padding: 20vh 0px;
    z-index: 999;

    @media (min-width: 1200px) {
        padding: 60vh 0px;
    }


    .imageFloat{
        position: absolute;
        top: 20px;
        width: 141px;
        height: 200px;
        object-fit: cover;
        // opacity: 1;
        // transform: scale(0.8) translateY(60px) rotate(-6deg);
        pointer-events: none;
        transition: all ease 0.2s;

        @media (min-width: 768px) {
            width: 198px;
            height: 280px;
        }
        @media (min-width: 1200px) {
            width: 300px;
            height: 425px;
        }
    }

    .sectionTitle{
        color: #000;
        text-align: center;
        font-family: Wix Madefor Display;
        font-size: 16px;
        font-weight: 700;
        line-height: 20px; /* 125% */
        text-transform: uppercase;
        position: relative;
    }

    .skillset{
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        border-bottom: 1px solid #000;
        position: relative;



        .markskill{
            width: 100%;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            align-self: stretch;
            border-top: 1px solid #000;
            position: relative;
            // background: red;

            .skill{
                display: flex;
                padding: 10px 20px;
                justify-content: center;
                align-items: center;
                gap: 10px;
                align-self: stretch;
                width: 100%;

                span{
                    color: #000;
                    leading-trim: both;
                    text-edge: cap;
                    font-family: Wix Madefor Display;
                    font-size: 32px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 32px; /* 100% */
                    letter-spacing: -1px;
                    text-transform: uppercase;

                    @media (min-width: 768px) {
                        font-size: 60px;
                    line-height: 60px; /* 100% */
                    letter-spacing: -2px;

                    }
                    @media (min-width: 1200px) {
                        font-size: 100px;
                    line-height: 100px; /* 100% */
                    letter-spacing: -5px;

                    }
                }
            }

            .skillHover{
                display: flex;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                // padding: 10px 20px;
                justify-content: center;
                align-items: center;
                gap: 10px;
                align-self: stretch;
                background: #FF471E;
                width: 100%;
                // height: 1px;
                overflow: hidden;
                // opacity: 0;
                transition: all ease 0.2s;


                span{
                    color: #FFF;
                    leading-trim: both;
                    text-edge: cap;
                    font-family: Wix Madefor Display;
                    font-size: 32px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 32px; /* 100% */
                    letter-spacing: -1px;
                    text-transform: uppercase;

                    @media (min-width: 768px) {
                        font-size: 60px;
                    line-height: 60px; /* 100% */
                    letter-spacing: -2px;

                    }

                    @media (min-width: 1200px) {
                        font-size: 100px;
                    line-height: 100px; /* 100% */
                    letter-spacing: -5px;


                    }
                }
            }
        }
    }
`;


export default SkillSection