import React, { useLayoutEffect, useRef } from "react";
import styled from 'styled-components';
import Uigif from "../assets/resize.gif";
import gsap from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useState } from "react";
gsap.registerPlugin(ScrollTrigger);

const Aboutme = () => {

    let content = useRef(null);
    let title = useRef(null);

    useLayoutEffect(() => {
        const tel = gsap.timeline();
        const text1 = content.children[0].children;
        const text2 = content.children[1].children;
        const text3 = content.children[2].children;



        tel
            .fromTo([title], {
                opacity: 0,
                y: 400
            },
                {
                    opacity: 1,
                    y: 0,
                    // stagger: 0.2,
                    // duration: 0.5,
                    ease: "power5.out",
                    scrollTrigger: {
                        trigger: "#aboutmee",
                        start: "center bottom",
                        end: "center center",
                        // markers: true,
                        scrub: 2,
                    }
                })
            .fromTo([text1], {
                opacity: 0,
                y: 400
            },
                {
                    opacity: 1,
                    y: 0,
                    // stagger: 0.2,
                    // duration: 0.5,
                    ease: "power5.out",
                    scrollTrigger: {
                        trigger: "#aboutmee",
                        start: "center bottom",
                        end: "center center",
                        // markers: true,
                        scrub: 2,
                    }
                })
            .fromTo([text2], {
                opacity: 0,
                y: 400
            },
                {
                    opacity: 1,
                    y: 0,
                    // stagger: 0.2,
                    // duration: 0.5,
                    ease: "power5.out",
                    scrollTrigger: {
                        trigger: "#aboutmee",
                        start: "center bottom",
                        end: "center center",
                        // markers: true,
                        scrub: 2
                    }
                })
            .fromTo([text3], {
                opacity: 0,
                y: 400
            },
                {
                    opacity: 1,
                    y: 0,
                    // stagger: 0.2,
                    // duration: 0.5,
                    ease: "power5.out",
                    scrollTrigger: {
                        trigger: "#aboutmee",
                        start: "center bottom",
                        end: "center center",
                        // markers: true,
                        scrub: 2
                    }
                })

    }, [])

    const [isHovering, setIsHovering] = useState(false);

    const handleMouseEnter = () => {
        setIsHovering(true);
    };

    const handleMouseLeave = () => {
        setIsHovering(false);
    };



    return (
        <AboutMe id='aboutmee'>
            <div className="mask">
                <span className='sectionTitle' ref={el => title = el}>About me</span>
            </div>
            <div className="flex-about" ref={el => content = el}>
                <div className="mask">
                    <p>I am a skilled <span className='mainColor' onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}>UI designer</span> with strong</p>
                </div>
                <div className="mask">
                    <p>focus on producing high quality & impactful</p>
                </div>
                <div className="mask">
                    <p>
                        <span className='mainColor' onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}>digital experience</span>.

                    </p>
                </div>
                <img style={{
                    opacity: isHovering ? '1' : '0',
                    transform: isHovering ? "scale(1) translateY(0px) rotate(2deg)" : "scale(0.8) translateY(20px) rotate(-6deg)"
                }} className="imageFloat" src={Uigif} alt="Examples of UI design" />


            </div>


        </AboutMe>
    )
}


const AboutMe = styled.div`
width: 100%;
padding: 25vh 10px;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
gap: 10px;

    .flex-about{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-self: stretch;
        position: relative;
    }

    .sectionTitle{
        color: #000;
        text-align: center;
        font-family: Wix Madefor Display;
        font-size: 12px;
        font-weight: 700;
        line-height: 18px; /* 125% */
        text-transform: uppercase;


        @media (min-width: 1200px) {
            font-size: 16px;
            line-height: 20px; /* 100% */
        }
    }


    .mask{
        width: 100%;
        max-height: 60px;
        height: auto;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: start;
        background: transparent;
        margin-bottom: 0px;


        @media (min-width: 1200px) {
            margin-bottom: -12px;
        }
    
    
    
        p{
            color: #000;
            text-align: center;
            font-family: Wix Madefor Display;
            font-size: 16px;
            font-weight: 700;
            line-height: 20px; /* 100% */


            @media (min-width: 1200px) {
                font-size: 48px;
                line-height: 60px; /* 100% */
            }
        }
      }

    .mainColor{
        color: #FF471E;
        cursor: pointer;
    }

    .imageFloat{
        position: absolute;
        top: -50px;
        transform-origin: center;
        mix-blend-mode: difference;
        width:300px;
        height: auto;
        object-fit: cover;
        // opacity: 1;
        transform: scale(1.2) translateY(-50%) rotate(-6deg);
        pointer-events: none;
        transition: all ease 0.2s;
    }

    
`


export default Aboutme


