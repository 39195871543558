import gsap from 'gsap';
import React from 'react'
import { useLayoutEffect } from 'react';
import styled from 'styled-components'
const PageLoader = (props) => {

    const { time } = props;

    const slidesAnimation = `slides ${time}s`;
    const slidersAnimation = `slides ${time}s`;



    useLayoutEffect(() => {

        setTimeout(() => {
            gsap.to('.logoLoad', {
                scale: 0,
                opacity: 0,
                ease: "power3.out",
            })
            gsap.to('.loaderBar', {
                scaleX: 0,
                ease: "power3.out",
            })
            gsap.to('.blinder', {
                scaleY: 0,
                duration: .2,
                stagger: .1,
                ease: "power3.out",
            })
            // gsap.fromTo('#cometo', {
            //     y: 500,
            //     opacity: 0,
            // }, {
            //     y: 0,
            //     opacity: 1,
            //     duration: .5,
            //     delay: -.2,
            //     ease: "power3.out",
            // })
        }, (time) * 1000);
    })

    return (
        <LoaderContainer>

            <div className="logoLoad">
                <svg viewBox="0 0 281 280" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <mask id="mask0_1671_213" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="195" y="69" width="86" height="142">
                        <path d="M260.822 210.755C273.024 189.981 280.024 165.78 280.024 139.944C280.024 114.108 273.024 89.9069 260.822 69.1328L195.457 139.944L260.822 210.755Z" fill="#FF471E" />
                    </mask>
                    <g id='maskg' mask="url(#mask0_1671_213)">
                        <path d="M260.822 210.755C273.024 189.981 280.024 165.78 280.024 139.944C280.024 114.108 273.024 89.9069 260.822 69.1328L195.457 139.944L260.822 210.755Z" fill="white" />
                        <rect className='rectm' style={{ animation: slidersAnimation }} x="195" y="69" height="142" fill="#FF471E" />
                    </g>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M238.984 239.008C213.649 264.336 178.654 280 140.001 280C62.6804 280 0 217.32 0 140C0 62.6802 62.6804 0 140.001 0C178.654 0 213.649 15.6644 238.984 40.992H190.506L99.5615 140L190.506 239.008H238.984ZM77.2803 68.9316L87.972 58.24L98.663 68.9316L87.972 79.6227L77.2803 68.9316ZM87.9396 51.922L84.7868 45.36L45.9202 64.0348L49.073 70.5967L87.9396 51.922Z" fill="#000000" />
                </svg>

            </div>
            <div className="loaderBar">
                <div className="slider" style={{ animation: slidesAnimation }}></div>
                <div className="loaderTxt">
                    <span>LOADING...</span>
                    <span>100%</span>
                </div>
            </div>
            <div className="pageBlinders">
                <div className="blinder"></div>
                <div className="blinder"></div>
                <div className="blinder"></div>
                <div className="blinder"></div>
                <div className="blinder"></div>
                <div className="blinder"></div>
            </div>
        </LoaderContainer>
    )
}


const LoaderContainer = styled.div`
width: 100%;
height: 100vh;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
fontfamily: Wix Madefor Display; 
font-size: 32px;
font-weight: 600;
gap: 50px;
color: #fff;
z-index: 99999;
position: fixed;
top: 0;
bottom: 0;
left:0;
right: 0;
pointer-events: none;


    .pageBlinders{
        position: absolute;
        top: 0;
        width: 100%;
        height: 100vh;
        display: flex;

        .blinder{
            background: #fff;
            border-right: 1px solid #eee;
            height: 100vh;
            flex: 1;
            transform-origin: top;
        }
    }

    @keyframes slides {
        0% {width: 0%;}
        // 25% {width: 70%;}
        100% {width: 100%;}
    }


    @keyframes sliders {
        0% {width: 0px;}
        100% {width: 36px;}
    }

    @media (min-width: 1200px) {
        @keyframes sliders {
            0% {width: 0px;}
            100% {width: 85px;}
        }
    }

    

    .loaderBar{
        width: 100%;
        height: 20px;
        background: #fff;
        display: flex;
        justify-content: start;
        align-items: center;
        position: absolute;
        top: 0;
        z-index: 999999;
        border-bottom: 1px solid #eee;

        @media (min-width: 1200px) {
            height: 60px;
            top: unset;
            bottom: 0;
            border-top: 1px solid #eee;

        }

        .loaderTxt{
            width: 100%;
            padding: 0px 20px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);

            @media (min-width: 1200px) {
                padding: 0px 80px;
            }

            span{
                color: #fff;
                font-family: Wix Madefor Display;
                font-size: 16px;
                font-weight: 600;
                line-height: 20px;
                letter-spacing: -0.8spx;
                text-transform: uppercase;

                @media (min-width: 1200px) {
                    font-size: 24px;
                    line-height: 40px;
                    letter-spacing: -1.2px;
                }
            }
        }

        .slider{
            position: relative;
            height: 20px;
            width: 10%;
            background: #FF471E;
            // animation: slides;

            @media (min-width: 1200px) {
                height: 60px;
            }
        }

        
    }
    .logoLoad{
        position: relative;
        z-index: 999999;
        position: relative;


        svg{
            width: 120px;
            height: 120px;
            position: relative;

            @media (min-width: 1200px) {
                width: 280px;
                height: 280px;
            }

            .rectm{
                max-width: 36px;
                width: 10px;
                position: relative;

                @media (min-width: 1200px) {
                    max-width: 85px;
                }
            }

            #maskg{
                width: 36px;
                height: 60px;

                @media (min-width: 1200px) {
                    width: 85px;
                    height: 142px;
                }
            }

        }
    }

    span{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 10px;
        
        img{
            height: 32px;
        }
    }

`

export default PageLoader