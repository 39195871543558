import React, { useLayoutEffect, useRef } from "react";
import styled from 'styled-components';
import SemiColon from "../assets/Semicolon.svg";
import gsap from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

const Quotes = () => {

    let content = useRef(null);
    // let aboutme = useRef(null);

    useLayoutEffect(() => {
        const tel = gsap.timeline();
        const text1 = content.children[0].children;
        const text2 = content.children[1].children;



        tel
            // .to("body",
            //     {
            //         "--color-bgc": "#ffffff",
            //         ease: "power5.out",
            //         scrollTrigger: {
            //             trigger: "#aboutme",
            //             start: "top center",
            //             end: "top top",
            //             // markers: true,
            //             scrub: 2,
            //         }
            //     })
            .fromTo([text1], {
                opacity: 0,
                y: 400
            },
                {
                    opacity: 1,
                    y: 0,
                    // stagger: 0.2,
                    // duration: 0.5,
                    ease: "power5.out",
                    scrollTrigger: {
                        trigger: "#aboutme",
                        start: "top center",
                        end: "top top",
                        // markers: true,
                        scrub: 2,
                    }
                })
            .fromTo([text2], {
                opacity: 0,
                y: 400
            },
                {
                    opacity: 1,
                    y: 0,
                    // stagger: 0.2,
                    // duration: 0.5,
                    ease: "power5.out",
                    scrollTrigger: {
                        trigger: "#aboutme",
                        start: "top center",
                        end: "top top",
                        // markers: true,
                        scrub: 2
                    }
                })


    }, [])




    return (
        <AboutMe id='aboutme'>
            <img src={SemiColon} alt="Semicolon" className="semiColon" />
            <div className="flex-about" ref={el => content = el}>
                <div className="mask">
                    <p>There is no design without <span className='mainColor'>discipline</span>.</p>
                </div>
                <div className="mask">
                    <p>There is no discipline without <span className='mainColor'>intelligence</span>.</p>
                </div>
            </div>
            <p className="quoteAuthor">
                Massimo Vignelli
            </p>


        </AboutMe>
    )
}


const AboutMe = styled.div`
width: 100%;
padding: 40vh 10px;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
gap: 10px;

    .flex-about{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-self: stretch;
        position: relative;
    }

    .quoteAuthor{
        color: #000;
        text-align: center;
        font-family: Wix Madefor Display;
        font-size: 12px;
        font-weight: 700;
        line-height: 18px;
        margin-top: 16px;

        @media (min-width: 1200px) {
            font-size: 16px;
            line-height: 20px; 
        }
    }

    .semiColon{
        height: 30px;
        width: auto;
        object-fit: contain;

        @media (min-width: 1200px) {
            height: 60px;
        }
    }


    .mask{
        width: 100%;
        height: auto;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: start;
        background: transparent;
        margin-bottom: 0px;
    
        @media (min-width: 1200px) {
            max-height: 60px;
        margin-bottom: -12px;

        }
    
        p{
            color: #000;
            text-align: center;
            font-family: Wix Madefor Display;
            font-size: 16px;
            font-weight: 700;
            line-height: 20px; /* 100% */

            @media (min-width: 1200px) {
                font-size: 48px;
                line-height: 60px; /* 100% */
            }
        }
      }

    .mainColor{
        color: #FF471E;
        cursor: pointer;
    }

    .imageFloat{
        position: absolute;
        top: 20px;
        mix-blend-mode: difference;
        width: 300px;
        height: auto;
        object-fit: cover;
        // opacity: 1;
        // transform: scale(0.8) translateY(60px) rotate(-6deg);
        pointer-events: none;
        transition: all ease 0.2s;
    }

    
`


export default Quotes;


