import Home from "./pages/Home";
import UDPage from "./pages/UDPage";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { PageProvider } from "./context/pageContext";
import Error404 from "./pages/Error404";
import { lazy, Suspense } from "react";
import PageLoading from "./pages/PageLoading";

function App() {
  const DesignWork = lazy(() => import("./pages/DesignWork"));
  const WorkCaseStudy = lazy(() => import("./pages/WorkCaseStudy"));
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <PageProvider>
              <Home />
            </PageProvider>
          }
        />
        <Route
          path="/pageUnderDevelopment"
          element={
            <PageProvider>
              <UDPage />
            </PageProvider>
          }
        />
        <Route
          path="/designWork"
          element={
            <PageProvider>
              <Suspense fallback={<PageLoading />}>
                <DesignWork />
              </Suspense>
            </PageProvider>
          }
        />
        <Route
          path="/designWork/:slug"
          element={
            <PageProvider>
              <Suspense fallback={<PageLoading />}>
                <WorkCaseStudy />
              </Suspense>
            </PageProvider>
          }
        />
        <Route
          path="*"
          element={
            <PageProvider>
              <Error404 />
            </PageProvider>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
