import React, { useLayoutEffect, useRef } from 'react';
import styled from 'styled-components';
import Creativity from "../assets/GraphicDesign.svg";
import Connection from "../assets/PosterDesign.svg";

import gsap from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger'
// import { preloadImages } from "../utils.js";
import images from '../skillList';

gsap.registerPlugin(ScrollTrigger);


function SkillSection() {


    let grids = useRef(null);
    let content = useRef(null);

    useLayoutEffect(() => {

        const gridWrap = grids.children[0];
        const gridItems = grids.querySelectorAll(".grid__item");
        const gridItemsInner = [...gridItems].map((item) =>
            item.querySelector(".grid__item-inner")
        );
        const contentText = content.children[1];



        const timeline = gsap.timeline({
            defaults: { ease: "none" },
            scrollTrigger: {
                trigger: gridWrap,
                start: "top bottom+=5%",
                end: "bottom top-=5%",
                scrub: true,
            },
        });


        // const tel = gsap.timeline();

        // tel.fromTo("#experience", {
        //     backgroundColor: "#ffffff",
        // }, {
        //     backgroundColor: "#000000",
        //     ease: "power5.out",
        //     scrollTrigger: {
        //         trigger: "#experience",
        //         start: "top center",
        //         end: "top top",
        //         // markers: true,
        //         scrub: 2,
        //     }
        // })


        // grids.style.setProperty("--grid-width", "160%");
        grids.style.setProperty("--grid-width", "200%");
        // grids.style.setProperty("--perspective", "2000px");
        grids.style.setProperty("--perspective", "1080px");
        grids.style.setProperty("--grid-inner-scale", "0.5");
        grids.style.setProperty("--grid-item-ratio", "0.8");
        grids.style.setProperty("--grid-columns", "6");
        // grids.style.setProperty("--grid-gap", "14vw");
        grids.style.setProperty("--grid-gap", "5vw");

        timeline
            .set(gridWrap, {
                rotationX: 20,
            })
            .set(gridItems, {
                z: () => gsap.utils.random(-3000, -1000),
            })
            .fromTo(
                gridItems,
                {
                    yPercent: () => gsap.utils.random(100, 1000),
                    rotationY: -45,
                    // filter: "brightness(200%)",
                },
                {
                    ease: "power2",
                    yPercent: () => gsap.utils.random(-1000, -100),
                    rotationY: 45,
                    // filter: "brightness(0%)",
                },
                0
            )
            .fromTo(
                gridWrap,
                {
                    rotationZ: -5,
                },
                {
                    rotationX: -20,
                    rotationZ: 10,
                    scale: 1.2,
                },
                0
            )
            .fromTo(
                gridItemsInner,
                {
                    scale: 2,
                },
                {
                    scale: 0.5,
                },
                0
            );






        const timeline3 = gsap.timeline({
            defaults: { ease: "none" },
            scrollTrigger: {
                trigger: content,
                start: "top bottom",
                end: "bottom top",
                scrub: true,
                // marker: true,
            },
        });

        timeline3
            .from(contentText, { y: 100, opacity: 0, ease: "power1.out" })
            .to(contentText, { y: 0, opacity: 1, ease: "power1.out" })
            .to(contentText, { y: -100, opacity: 0, ease: "power1.out" }, +4);


    }, [])


    return (
        <Experience id='experience'>
            <section className="content" ref={el => content = el} id="twox">
                <div className="grid grid--2" ref={el => grids = el}>
                    <div className="grid-wrap">
                        <div className="grid__item">
                            <img className="grid__item-inner" src={images.img1} alt='Random Poster' />
                        </div>

                        <div className="grid__item">
                            <img className="grid__item-inner" src={images.img10} alt='Random Poster' />
                        </div>

                        <div className="grid__item">
                            <img className="grid__item-inner" src={images.img14} alt='Random Poster' />
                        </div>
                        <div className="grid__item">
                            <img className="grid__item-inner" src={images.img15} alt='Random Poster' />
                        </div>
                        <div className="grid__item">
                            <img className="grid__item-inner" src={images.img18} alt='Random Poster' />
                        </div>
                        <div className="grid__item">
                            <img className="grid__item-inner" src={images.img19} alt='Random Poster' />
                        </div>
                        <div className="grid__item">
                            <img className="grid__item-inner" src={images.img20} alt='Random Poster' />
                        </div>

                        <div className="grid__item">
                            <img className="grid__item-inner" src={images.img26} alt='Random Poster' />
                        </div>
                        <div className="grid__item">
                            <img className="grid__item-inner" src={images.img27} alt='Random Poster' />
                        </div>

                        <div className="grid__item">
                            <img className="grid__item-inner" src={images.img29} alt='Random Poster' />
                        </div>

                        <div className="grid__item">
                            <img className="grid__item-inner" src={images.img31} alt='Random Poster' />
                        </div>

                        <div className="grid__item">
                            <img className="grid__item-inner" src={images.img34} alt='Random Poster' />
                        </div>
                        <div className="grid__item">
                            <img className="grid__item-inner" src={images.img35} alt='Random Poster' />
                        </div>
                        <div className="grid__item">
                            <img className="grid__item-inner" src={images.img36} alt='Random Poster' />
                        </div>

                        <div className="grid__item">
                            <img className="grid__item-inner" src={images.img39} alt='Random Poster' />
                        </div>
                        <div className="grid__item">
                            <img className="grid__item-inner" src={images.img40} alt='Random Poster' />
                        </div>
                        <div className="grid__item">
                            <img className="grid__item-inner" src={images.img41} alt='Random Poster' />
                        </div>

                        <div className="grid__item">
                            <img className="grid__item-inner" src={images.img44} alt='Random Poster' />
                        </div>

                        <div className="grid__item">
                            <img className="grid__item-inner" src={images.img48} alt='Random Poster' />
                        </div>
                        <div className="grid__item">
                            <img className="grid__item-inner" src={images.img49} alt='Random Poster' />
                        </div>

                        <div className="grid__item">
                            <img className="grid__item-inner" src={images.img51} alt='Random Poster' />
                        </div>
                        <div className="grid__item">
                            <img className="grid__item-inner" src={images.img52} alt='Random Poster' />
                        </div>

                        <div className="grid__item">
                            <img className="grid__item-inner" src={images.img55} alt='Random Poster' />
                        </div>

                        <div className="grid__item">
                            <img className="grid__item-inner" src={images.img58} alt='Random Poster' />
                        </div>

                        <div className="grid__item">
                            <img className="grid__item-inner" src={images.img62} alt='Random Poster' />
                        </div>
                        <div className="grid__item">
                            <img className="grid__item-inner" src={images.img63} alt='Random Poster' />
                        </div>
                        <div className="grid__item">
                            <img className="grid__item-inner" src={images.img64} alt='Random Poster' />
                        </div>

                        <div className="grid__item">
                            <img className="grid__item-inner" src={images.img66} alt='Random Poster' />
                        </div>

                        <div className="grid__item">
                            <img className="grid__item-inner" src={images.img68} alt='Random Poster' />
                        </div>

                        <div className="grid__item">
                            <img className="grid__item-inner" src={images.img70} alt='Random Poster' />
                        </div>

                        <div className="grid__item">
                            <img className="grid__item-inner" src={images.img73} alt='Random Poster' />
                        </div>

                        <div className="grid__item">
                            <img className="grid__item-inner" src={images.img75} alt='Random Poster' />
                        </div>
                        <div className="grid__item">
                            <img className="grid__item-inner" src={images.img76} alt='Random Poster' />
                        </div>
                        <div className="grid__item">
                            <img className="grid__item-inner" src={images.img77} alt='Random Poster' />
                        </div>
                        <div className="grid__item">
                            <img className="grid__item-inner" src={images.img78} alt='Random Poster' />
                        </div>
                        <div className="grid__item">
                            <img className="grid__item-inner" src={images.img79} alt='Random Poster' />
                        </div>
                        <div className="grid__item">
                            <img className="grid__item-inner" src={images.img80} alt='Random Poster' />
                        </div>
                        <div className="grid__item">
                            <img className="grid__item-inner" src={images.img81} alt='Random Poster' />
                        </div>
                        <div className="grid__item">
                            <img className="grid__item-inner" src={images.img82} alt='Random Poster' />
                        </div>

                        <div className="grid__item">
                            <img className="grid__item-inner" src={images.img84} alt='Random Poster' />
                        </div>
                        <div className="grid__item">
                            <img className="grid__item-inner" src={images.img85} alt='Random Poster' />
                        </div>

                        <div className="grid__item">
                            <img className="grid__item-inner" src={images.img88} alt='Random Poster' />
                        </div>
                        <div className="grid__item">
                            <img className="grid__item-inner" src={images.img89} alt='Random Poster' />
                        </div>

                        <div className="grid__item">
                            <img className="grid__item-inner" src={images.img92} alt='Random Poster' />
                        </div>
                        <div className="grid__item">
                            <img className="grid__item-inner" src={images.img93} alt='Random Poster' />
                        </div>

                        <div className="grid__item">
                            <img className="grid__item-inner" src={images.img96} alt='Random Poster' />
                        </div>

                        <div className="grid__item">
                            <img className="grid__item-inner" src={images.img100} alt='Random Poster' />
                        </div>
                    </div>
                </div>
                <h3 id="two" className="content__title">
                    <div className="flex-about">
                        <div className="mask">
                            <img id="creative" src={Creativity} alt="Graphic Design" />
                        </div>
                        <div className="mask mix-blend-difference">
                            <img id="connect" src={Connection} alt="Poster Design" />
                        </div>
                    </div>
                </h3>
            </section>
        </Experience>
    )
}

const Experience = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 10vh;
    padding-bottom: 10vh;
    position: relative;
    overflow: hidden;

    .mix-blend-difference{
        mix-blend-mode: difference;
    }


    /* Scroll3D Grid */



    .content {
    position: relative;
    margin-bottom: 20vh;
    }

    .content--spacing {
    margin-bottom: 100vh;
    }



    .content__title {
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 50%;
    left: 50%;
    color: #000;
    margin: -50vh 0 0 -50vw;
    // padding: 0 10vw;
    // display: grid;
    // place-items: center;
    // text-align: center;
    // font-weight: 600;
    // font-size: 48px;
    // mix-blend-mode: difference;
    pointer-events: none;


    .flex-about{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-self: stretch;
        margin: 40vh 0;
      }
    
      .mask{
        width: 100%;
        max-height: 100px;
        height: auto;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background: transparent;
        margin-bottom: 5px;
    
    
    
        #creative{
          max-height: 85px;
        }
        #connect{
          max-height: 85px;
        }
        
      }

    }

    .content__title--top {
    align-items: start;
    }

    .content__title--bottom {
    align-items: end;
    }

    .content__title--left {
    justify-items: start;
    text-align: left;
    }

    .content__title--right {
    justify-items: end;
    text-align: right;
    }

    .outro {
    display: grid;
    place-items: center;
    margin: 40vh 0;
    }

    .grid {
    display: grid;
    place-items: center;
    padding: 2rem;
    width: 100%;
    perspective: var(--perspective);
    }

    .grid-wrap {
    height: var(--grid-height);
    width: var(--grid-width);
    display: grid;
    grid-template-columns: repeat(var(--grid-columns), 1fr);
    gap: var(--grid-gap);
    transform-style: preserve-3d;
    }

    .grid__item {
    aspect-ratio: var(--grid-item-ratio);
    width: 100%;
    height: auto;
    overflow: hidden;
    position: relative;
    border-radius: 8px;
    display: grid;
    place-items: center;
    }

    .grid__item-inner {
    position: relative;
    // width: calc(1 / var(--grid-inner-scale) * 105%);
    width: 56%;
    // height: calc(1 / var(--grid-inner-scale) * 148.5%);
    background-size: cover;
    background-position: top center;
    object-fit: cover;
    filter: brightness(0.8);

    }

    @media screen and (min-width: 53em) {
    .frame {
        grid-template-columns: auto auto auto 1fr;
        grid-template-areas: 'title prev ... sponsor';
        align-content: space-between;
        justify-items: start;
        grid-gap: 2rem;
    }

    .frame__demos {
        justify-self: end;
    }

    .content--outro {
        height: 100vh;
        justify-content: center;
    }


    
    }
`;



export default SkillSection