import React from 'react'
import Header from '../Components/Header'
import PageLoader from '../Components/PageLoader'
import Hero from '../Components/Hero'
import About from '../Components/About'
import SkillSection from '../Components/SkillSection'
import Work from '../Components/Work'
import Footer from '../Components/Footer'
import Experience from '../Components/Experience'
import Marquee from '../Components/Marquee'
import Aboutme from '../Components/Aboutme'
import Quotes from '../Components/Quotes'
import noise from "../assets/noise.gif"
import styled from 'styled-components'
import { useState } from 'react'
import { useEffect } from 'react'
import ProgressBar from '../Components/ProgressBar'

function Home() {

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false);
        }, 12000)
    }, [])

    return (
        <>
            <Image />
            {isLoading ? (<PageLoader time={10} />) : ("")}
            {/* <PageLoader time={5} /> */}
            <ProgressBar />
            <Header />
            <Hero />
            <Marquee />
            <About />
            <Aboutme />
            <SkillSection />
            <Experience />
            <Quotes />
            <Work />
            <Footer />

        </>
    )
}

const Image = styled.div`
background-image: url(${noise});
background-size: 70px;
opacity: 0.15;
height: 100%;
width: 100%;
position: fixed;
top: 0;
left: 0;
z-index: 999999999;
pointer-events: none;
`


export default Home