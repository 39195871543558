import React from 'react'
import styled from 'styled-components'
import ErrImg from "../assets/Error.svg"
import { Link } from 'react-router-dom'

const PageUnderdevelopment = () => {
    return (
        <PageSection>
            <h3>
                This page is <span>under development</span>....
                <br />comeback after sometime
            </h3>

            <img src={ErrImg} alt="Page is Under development" />
            <Link to="/" >Home</Link>
        </PageSection>
    )
}


const PageSection = styled.div`
width: 100%;
height: 100vh;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
gap: 10px;
padding: 10px;

    h3{
        color: #000;
        text-align: center;
        font-family: Wix Madefor Display;
        font-size: 16px;
        font-weight: 700;
        line-height: 20px; /* 125% */
        text-transform: uppercase;
        

        span{
            color: #FF471E;
        }
    }

    img{
        max-width: 400px;
        height: auto;
        object-fit: cover;
    }
    a{
        color: #000;
        font-family: Wix Madefor Display;
        font-size: 16px;
        font-weight: 700;
        line-height: 20px; /* 125% */
        text-transform: uppercase;
        display: flex;
        max-width: 400px;
        width: 100%;
        padding: 8px 16px;
        justify-content: center;
        align-items: center;
        border: 1px solid #000;
        transition: all ease 0.2s;

        &:hover{
            background: #000;
            color: #fff;
        }
    }
`


export default PageUnderdevelopment