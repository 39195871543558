import React from 'react'
import styled from 'styled-components'
import Diamond from '../assets/diamond.svg'


const Marquee = () => {
    return (
        <MarqueeCard>
            <div className="marquee plus6">
                <div className="scroll marqueeContent">
                    <p>Human</p>
                    <img src={Diamond} alt='Diamond' width={18} height={18} />
                    <p>UI Designer</p>
                    <img src={Diamond} alt='Diamond' width={18} height={18} />
                    <p>Web Developer</p>
                    <img src={Diamond} alt='Diamond' width={18} height={18} />
                    <p>3D Generalist</p>
                    <img src={Diamond} alt='Diamond' width={18} height={18} />
                    <p>Graphic Designer</p>
                    <img src={Diamond} alt='Diamond' width={18} height={18} />
                    <p>Creator</p>
                    <img src={Diamond} alt='Diamond' width={18} height={18} />
                    <p>Artist</p>
                    <img src={Diamond} alt='Diamond' width={18} height={18} />
                </div>

                <div className="scroll marqueeContent">
                    <p>Human</p>
                    <img src={Diamond} alt='Diamond' width={18} height={18} />
                    <p>UI Designer</p>
                    <img src={Diamond} alt='Diamond' width={18} height={18} />
                    <p>Web Developer</p>
                    <img src={Diamond} alt='Diamond' width={18} height={18} />
                    <p>3D Generalist</p>
                    <img src={Diamond} alt='Diamond' width={18} height={18} />
                    <p>Graphic Designer</p>
                    <img src={Diamond} alt='Diamond' width={18} height={18} />
                    <p>Creator</p>
                    <img src={Diamond} alt='Diamond' width={18} height={18} />
                    <p>Artist</p>
                    <img src={Diamond} alt='Diamond' width={18} height={18} />
                </div>
                <div className="scroll marqueeContent">
                    <p>Human</p>
                    <img src={Diamond} alt='Diamond' width={18} height={18} />
                    <p>UI Designer</p>
                    <img src={Diamond} alt='Diamond' width={18} height={18} />
                    <p>Web Developer</p>
                    <img src={Diamond} alt='Diamond' width={18} height={18} />
                    <p>3D Generalist</p>
                    <img src={Diamond} alt='Diamond' width={18} height={18} />
                    <p>Graphic Designer</p>
                    <img src={Diamond} alt='Diamond' width={18} height={18} />
                    <p>Creator</p>
                    <img src={Diamond} alt='Diamond' width={18} height={18} />
                    <p>Artist</p>
                    <img src={Diamond} alt='Diamond' width={18} height={18} />
                </div>
            </div>


            <div className="marquee minus6">
                <div className="scroll marqueeContent">
                    <p>Human</p>
                    <img src={Diamond} alt='Diamond' width={18} height={18} />
                    <p>UI Designer</p>
                    <img src={Diamond} alt='Diamond' width={18} height={18} />
                    <p>Web Developer</p>
                    <img src={Diamond} alt='Diamond' width={18} height={18} />
                    <p>3D Generalist</p>
                    <img src={Diamond} alt='Diamond' width={18} height={18} />
                    <p>Graphic Designer</p>
                    <img src={Diamond} alt='Diamond' width={18} height={18} />
                    <p>Creator</p>
                    <img src={Diamond} alt='Diamond' width={18} height={18} />
                    <p>Artist</p>
                    <img src={Diamond} alt='Diamond' width={18} height={18} />
                </div>

                <div className="scroll marqueeContent reverse">
                    <p>Human</p>
                    <img src={Diamond} alt='Diamond' width={18} height={18} />
                    <p>UI Designer</p>
                    <img src={Diamond} alt='Diamond' width={18} height={18} />
                    <p>Web Developer</p>
                    <img src={Diamond} alt='Diamond' width={18} height={18} />
                    <p>3D Generalist</p>
                    <img src={Diamond} alt='Diamond' width={18} height={18} />
                    <p>Graphic Designer</p>
                    <img src={Diamond} alt='Diamond' width={18} height={18} />
                    <p>Creator</p>
                    <img src={Diamond} alt='Diamond' width={18} height={18} />
                    <p>Artist</p>
                    <img src={Diamond} alt='Diamond' width={18} height={18} />
                </div>
                <div className="scroll marqueeContent reverse">
                    <p>Human</p>
                    <img src={Diamond} alt='Diamond' width={18} height={18} />
                    <p>UI Designer</p>
                    <img src={Diamond} alt='Diamond' width={18} height={18} />
                    <p>Web Developer</p>
                    <img src={Diamond} alt='Diamond' width={18} height={18} />
                    <p>3D Generalist</p>
                    <img src={Diamond} alt='Diamond' width={18} height={18} />
                    <p>Graphic Designer</p>
                    <img src={Diamond} alt='Diamond' width={18} height={18} />
                    <p>Creator</p>
                    <img src={Diamond} alt='Diamond' width={18} height={18} />
                    <p>Artist</p>
                    <img src={Diamond} alt='Diamond' width={18} height={18} />
                </div>
            </div>
        </MarqueeCard>
    )
}


const MarqueeCard = styled.div`
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
overflow-x: clip;
justify-content: center;
margin-top: 40px;

    @media (min-width: 1200px) {
        margin-top: 0px;
    }

    .marquee{
        display: flex;
        padding: 10px 20px;
        width: 110%;
        overflow: hidden;
        align-items: center;
        justify-content: center;
        gap: 20px;
        border: 1px solid #FFF;
        background: #000;
        transform-origin: center center;

        .marqueeContent{
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            min-width: 100%;
            -webkit-justify-content: space-around;
            -ms-flex-pack: distribute;
            justify-content: space-around;
            -webkit-box-flex: 0;
            -webkit-flex: 0 0 auto;
            -ms-flex: 0 0 auto;
            flex: 0 0 auto;
            grid-column-gap: 1rem;
        }
        
        @keyframes scroll {
            from {
            transform: translateX(0);
            }
            to {
            transform: translateX(calc(-100% - 1rem));
            }
        }
        
        .scroll {
            animation: scroll 10s linear infinite;
        }
        
        .reverse {
            animation-direction: reverse;
        }
        p{
            color: #FFF;
            font-family: Wix Madefor Display;
            font-size: 24px;
            font-weight: 600;
            line-height: 32px;
        }
    }
    .plus6 {
        transform: rotate(3deg);
        mix-blend-mode: difference;

    }
    .minus6 {
        transform: rotate(-3deg);
        margin-top: -60px;
    }

`

export default Marquee