import React from 'react'
import noise from "../assets/noise.gif"
import styled from 'styled-components'

function PageLoading() {
    return (
        <>
            <Image />
            <LoadingSection>
                <h1>
                    Loading...
                </h1>
            </LoadingSection>
        </>
    )
}

const Image = styled.div`
background-image: url(${noise});
background-size: 70px;
opacity: 0.15;
height: 100%;
width: 100%;
position: fixed;
top: 0;
left: 0;
z-index: 999999999;
pointer-events: none;



`

const LoadingSection = styled.div`
width: 100%;
height: 100vh;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
background: radial-gradient(207.62% 141.42% at 0% 0%, #373737 0%, #000 100%);

h1{
    color: #FFF;
    font-family: Wix Madefor Display;
    font-size: 44px;
    font-style: normal;
    font-weight: 600;
    line-height: 50px;
    letter-spacing: -2.2px;
    text-transform: uppercase;
}

`


export default PageLoading