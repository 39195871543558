import React from 'react'
import styled from 'styled-components'
import logoFooter from '../assets/Logos/pixllogo.svg'
import Pin from '../assets/location_on.svg'

function Footer() {

    return (
        <FooterSection>
            <div className="footerBox">
                <div className="footerContainer">
                    <p><span>Prakhar</span>Srivastava</p>

                    <a href="#hero"><img src={logoFooter} alt="Logo Footer" /></a>
                </div>
                <div className="footerFlex">
                    <div className="socialLinks">
                        <a href="https://www.behance.net/thepixelateit">
                            behance.net
                            <span>/thepixelateit</span>
                        </a>
                        <a href="https://www.instagram.com/thepixelateit/">
                            instagram.com
                            <span>/thepixelateit</span>
                        </a>
                        <a href="https://dribbble.com/pixelateit">
                            dribbble.com
                            <span>/pixelateit</span>
                        </a>
                        <a href="https://www.linkedin.com/in/pixelateit/">
                            linkedin.com
                            <span>/in/pixelateit/</span>
                        </a>
                    </div>
                    <div className='footerText'>
                        <div className="titleText">
                            <h2>
                                Have a great<br />
                                Idea to Share?
                            </h2>
                            <p>
                                or want to have a cup of coffee and chit chat, just drop me a message
                            </p>
                        </div>
                        <p onClick={() => {
                            navigator.clipboard.writeText('PrakharS@protonmail.com');
                        }}>info@pixelateit.co.in</p>
                    </div>
                </div>
            </div>
            <div className="footerCopy">
                <div className="footerContainer">
                    <p className='flex flex-row gap-2'><img src={Pin} height={20} width={20} alt="Location: Lucknow, Uttar Pradesh, India" />Lucknow, In</p>
                    <p>Copyright © 2023 Pixelateit</p>
                </div>
            </div>
        </FooterSection>
    )
}


const FooterSection = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    padding: 12px;
    align-items: center;
    justify-content: center;

    p{
        color: #FFF;
        font-family: Wix Madefor Display;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 125% */
        text-decoration: none;
        cursor: pointer;
        position: relative;
    }

        .footerContainer{
            width: 100%;
            height: auto;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            row-gap: 20px;


            p {
                color: #FFF;
                font-family: Wix Madefor Display;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
    
                span {
                    font-weight: 700;
                    color: #FF471E;
                }

                @media (min-width: 1200px) {
                    font-size: 20px;
                    line-height: 24px;
                }
            }

            img{
                height: 16px;
                object-fit: cover;

                @media (min-width: 1200px) {
                    height: auto;
                }
            }
        }


    .footerBox {
        width: 100%;
        max-width: 1400px;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 40px 24px 60px 24px;
        row-gap: 20px;
        gap: 40px;
        background: rgba(0, 0, 0, 0.90);
        backdrop-filter: blur(5px);
        border-radius: 8px 8px 0 0;

        


        @media (min-width: 768px) {
            // width: 680px;
            // flex-direction: row;
            justify-content: space-between;
        }
        

        .footerFlex {
            display: flex;
            width: 100%;
            flex-direction: column;
            gap: 40px;
            align-items:  center;
            justify-content: center;

            @media (min-width: 768px) {
                flex-direction: row;
                justify-content: space-between;
            }

            .footerText{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 40px;
                width: 100%;



                .titleText{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    gap: 8px;
                    align-self: stretch;


                    h2{
                        color: #FFF;
                        font-family: Wix Madefor Display;
                        font-size: 32px;
                        font-weight: 700;
                        line-height: 32px; /* 87.5% */
                        text-transform: uppercase;

                        @media (min-width: 1200px) {
                            font-size: 48px;
                            line-height: 42px; /* 87.5% */
                        }
                    }
                    p{
                        color: rgba(255, 255, 255, 0.30);
                        max-width: 410px;
                        font-family: Wix Madefor Display;
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 20px; /* 125% */
                        pointer-events: none;
                    }
                }
                }
                .socialLinks {
                    display: flex;
                    width: 100%;
                    flex-direction: column;
                    gap: 8px;
                    align-items: flex-start;

                    >a {
                        display: flex;
                        flex-direction: row;
                        color: #FFF;
                        font-family: Wix Madefor Display;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 20px; /* 125% */
                        text-decoration: none;
                        
                        
                        >span{
                            color: rgba(255, 255, 255, 0.50);
                            font-family: Wix Madefor Display;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 20px; /* 125% */
                            opacity: 0;
                        }

                        &:hover span {
                            opacity: 1;
                        }
                    }
                }
        }
    }

    .footerCopy{
        width: 100%;
        max-width: 1400px;
        border-top: 1px solid rgba(255, 255, 255, 0.20);
        display: flex;
        align-items: center;
        padding: 24px;
        justify-content: center;
        background: rgba(0, 0, 0, 0.90);
        backdrop-filter: blur(5px);
        border-radius: 0 0 8px 8px;
        overflow: hidden;


        .footerContainer{
            width: 100%;
            height: auto;
            display: flex;
            flex-direction: column;
            align-items: start;
            justify-content: space-between;
            gap: 5px;


            @media (min-width: 768px) {
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
            }
        }

        p {
            color: #FFF;
            font-family: Wix Madefor Display;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;

            img {
                object-fit: cover;
                height: 20px;
                width: 20px;
                margin-right: 5px;
            }

            span {
                font-weight: 700;
                color: #FF471E;
            }
        }
    }
`;

export default Footer