import React, { useLayoutEffect, useRef } from 'react';

import styled from 'styled-components'
import images from '../skillList';

import GoodDesign from "../assets/GoodDesign.svg";
import isHonest from "../assets/isHonest.svg";
import gsap from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger'
// import { preloadImages } from "../utils.js";
gsap.registerPlugin(ScrollTrigger);

function Work() {

    let grids = useRef(null);
    let content = useRef(null);

    useLayoutEffect(() => {

        const gridWrap = grids.children[0];
        const gridItems = gridWrap.querySelectorAll(".grid__item");

        const contentText = content.children[1];



        const timelinen = gsap.timeline({
            defaults: { ease: "none" },
            scrollTrigger: {
                trigger: gridWrap,
                start: "top bottom+=5%",
                end: "bottom top-=5%",
                scrub: true,
            },
        });


        // Set some CSS related style values
        grids.style.setProperty("--grid-width", "50%");
        grids.style.setProperty("--perspective", "3000px");
        // grids.style.setProperty("--perspective", "1500px");
        grids.style.setProperty("--grid-item-ratio", "0.8");
        grids.style.setProperty("--grid-columns", "3");
        grids.style.setProperty("--grid-gap", "1vw");

        timelinen
            .set(gridWrap, {
                transformOrigin: "0% 50%",
                rotationY: 30,
                xPercent: -75,
            })
            .set(gridItems, {
                transformOrigin: "50% 0%",
            })
            .to(
                gridItems,
                {
                    duration: 0.5,
                    ease: "power2",
                    z: 500,
                    stagger: 0.04,
                },
                0
            )
            .to(
                gridItems,
                {
                    duration: 0.5,
                    ease: "power2.in",
                    z: 0,
                    stagger: 0.04,
                },
                0.5
            )
            .fromTo(
                gridItems,
                {
                    rotationX: -70,
                    // filter: "brightness(120%)",
                },
                {
                    duration: 1,
                    rotationX: 70,
                    // filter: "brightness(0%)",
                    stagger: 0.04,
                },
                0
            );

        // Preload images, initialize smooth scrolling, apply scroll-triggered animations, and remove loading class from body
        // preloadImages(".grid__item-inner").then(() => {
        //     document.body.classList.remove("loading");
        // });



        const timeline5 = gsap.timeline({
            defaults: { ease: "none" },
            scrollTrigger: {
                trigger: content,
                start: "top bottom",
                end: "bottom top",
                scrub: true,
                marker: true,
            },
        });

        timeline5
            .from(contentText, { y: 100, opacity: 0, ease: "power1.out", delay: 0.8 })
            .to(contentText, { y: 0, opacity: 1, ease: "power1.out" })
            .to(contentText, { y: -100, opacity: 0, ease: "power1.out" }, +4);

    }, [])

    return (
        <WorkSection id='work'>
            <section id="fourx" className="content content--spacing" ref={el => content = el}>
                <div className="grid grid--4" ref={el => grids = el}>
                    <div className="grid-wrap">
                        <div className="grid__item">
                            <img className="grid__item-inner" src={images.workImg1} alt='Work Related Posts' />

                            {/* <div
                                className="grid__item-inner"
                                style={{ backgroundImage: `url(${images.workImg1})` }}
                            ></div> */}
                        </div>

                        <div className="grid__item">
                            <img className="grid__item-inner" src={images.workImg2} alt='Work Related Posts' />
                        </div>

                        <div className="grid__item">
                            <img className="grid__item-inner" src={images.workImg3} alt='Work Related Posts' />
                        </div>

                        <div className="grid__item">
                            <img className="grid__item-inner" src={images.workImg4} alt='Work Related Posts' />
                        </div>

                        <div className="grid__item">
                            <img className="grid__item-inner" src={images.workImg5} alt='Work Related Posts' />
                        </div>

                        <div className="grid__item">
                            <img className="grid__item-inner" src={images.workImg6} alt='Work Related Posts' />
                        </div>

                        <div className="grid__item">
                            <img className="grid__item-inner" src={images.workImg7} alt='Work Related Posts' />
                        </div>

                        <div className="grid__item">
                            <img className="grid__item-inner" src={images.workImg8} alt='Work Related Posts' />
                        </div>

                        <div className="grid__item">
                            <img className="grid__item-inner" src={images.workImg9} alt='Work Related Posts' />
                        </div>

                        <div className="grid__item">
                            <img className="grid__item-inner" src={images.workImg10} alt='Work Related Posts' />
                        </div>

                        <div className="grid__item">
                            <img className="grid__item-inner" src={images.workImg11} alt='Work Related Posts' />
                        </div>

                        <div className="grid__item">
                            <img className="grid__item-inner" src={images.workImg12} alt='Work Related Posts' />
                        </div>

                        <div className="grid__item">
                            <img className="grid__item-inner" src={images.workImg13} alt='Work Related Posts' />
                        </div>

                        <div className="grid__item">
                            <img className="grid__item-inner" src={images.workImg14} alt='Work Related Posts' />
                        </div>

                        <div className="grid__item">
                            <img className="grid__item-inner" src={images.workImg15} alt='Work Related Posts' />
                        </div>

                        <div className="grid__item">
                            <img className="grid__item-inner" src={images.workImg16} alt='Work Related Posts' />
                        </div>

                        <div className="grid__item">
                            <img className="grid__item-inner" src={images.workImg17} alt='Work Related Posts' />
                        </div>

                        <div className="grid__item">
                            <img className="grid__item-inner" src={images.workImg18} alt='Work Related Posts' />
                        </div>

                        <div className="grid__item">
                            <img className="grid__item-inner" src={images.workImg19} alt='Work Related Posts' />
                        </div>

                        <div className="grid__item">
                            <img className="grid__item-inner" src={images.workImg20} alt='Work Related Posts' />
                        </div>

                        <div className="grid__item">
                            <img className="grid__item-inner" src={images.workImg21} alt='Work Related Posts' />
                        </div>

                        <div className="grid__item">
                            <img className="grid__item-inner" src={images.workImg22} alt='Work Related Posts' />
                        </div>

                        <div className="grid__item">
                            <img className="grid__item-inner" src={images.workImg23} alt='Work Related Posts' />
                        </div>

                        <div className="grid__item">
                            <img className="grid__item-inner" src={images.workImg24} alt='Work Related Posts' />
                        </div>



                    </div>
                </div>
                <div id="four" className="content__title content__title--right">
                    <div className="flex-about">
                        <span className='sectionTitle'>My Motto</span>
                        <div className="mask">
                            <img id="creative" src={GoodDesign} alt="Good Design" />
                        </div>
                        <div className="mask mix-blend-difference">
                            <img id="connect" src={isHonest} alt="is Honest" />
                        </div>
                        <p className="quoteAuthor">
                            Dieter Rams
                        </p>
                    </div>
                </div>
            </section>


        </WorkSection>
    )


}

const WorkSection = styled.div`

/* Scroll3D Grid */

.card-wrap {
	margin-top: 5vh;
	display: grid;
	grid-gap: 2rem;
	grid-auto-flow: row;
	grid-template-columns: 250px;
}

.quoteAuthor{
    color: #000;
    text-align: center;
    font-family: Wix Madefor Display;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px; /* 125% */
    margin-top: 10px;
}

.sectionTitle{
    color: #000;
    text-align: center;
    font-family: Wix Madefor Display;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px; /* 125% */
    text-transform: uppercase;
    margin-bottom: 10px;

}

.flex-about{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    margin: 40vh 0;
  }

  .mask{
    width: 100%;
    max-height: 100px;
    height: auto;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: transparent;
    margin-bottom: 5px;



    #creative{
      max-height: 85px;
    }
    #connect{
      max-height: 85px;
    }
    #gods{
      max-height: 85px;
    }
  }

}

.card__image {
	display: block;
	border-radius: 7px;
	background-size: cover;
	background-position: 50% 50%;
	width: 100%;
	height: auto;
	aspect-ratio: 4 / 3;
	filter: contrast(0.8);
}

.credits {
	font-size: 1.5rem;
	text-align: center;
	margin: 50vh auto 0;
	padding-bottom: 50vh;
}

.content {
	position: relative;
	margin-bottom: 20vh;
}

.content--spacing {
	margin-bottom: 100vh;
}

.content__title {
	position: fixed;
	height: 100vh;
	width: 100vw;
	top: 50%;
	left: 50%;
	margin: -50vh 0 0 -50vw;
	padding: 0 10vw;
	display: grid;
	place-items: center;
	text-align: center;
	font-weight: 300;
	font-size: clamp(1.5rem, 15vw, 6.5rem);
    pointer-events: none;
}

.content__title--top {
	align-items: start;
}

.content__title--bottom {
	align-items: end;
}

.content__title--left {
	justify-items: start;
	text-align: left;
}

.content__title--right {
	justify-items: end;
	text-align: right;
}

.outro {
	display: grid;
	place-items: center;
	margin: 40vh 0;
}

.grid {
	display: grid;
	place-items: center;
	padding: 2rem;
	width: 100%;
	perspective: var(--perspective);
}

.grid-wrap {
	height: var(--grid-height);
	width: var(--grid-width);
	display: grid;
	grid-template-columns: repeat(var(--grid-columns), 1fr);
	gap: var(--grid-gap);
	transform-style: preserve-3d;
}

.grid__item {
	aspect-ratio: var(--grid-item-ratio);
	width: 100%;
	height: auto;
	overflow: hidden;
	position: relative;
	border-radius: 0px;
	display: grid;
	place-items: center;
    box-shadow: -20px 20px 40px 0px rgba(39, 42, 46, 0.15);
}

.grid__item-inner {
	position: relative;
	width: calc(1 / var(--grid-inner-scale) * 100%);
	height: calc(1 / var(--grid-inner-scale) * 100%);
	background-size: cover;
	background-position: top center;
    cursor: pointer;
    object-fit: cover;
}
`;



export default Work