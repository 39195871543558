import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import LogoHeader from "../assets/Logos/pixllogo.svg";
import { Link } from "react-router-dom";



function Header() {

  const menuBtn = useRef();
  const navMenuContainer = useRef();


  const [display, setDisplay] = useState(false);


  useEffect(() => {
    menuBtn.current.addEventListener("click", () => {
      setDisplay(!display);
      // navMenuContainer.current.classList.toggle("menuDisplay");
      // menuBtn.current.classList.toggle("menuClose");
    })

  }, [display])





  return (
    <HeaderSection className="header">
      <div className="navContainer" >
        <a className="logo" href="https://www.pixelateit.co.in/"><img src={LogoHeader} width="164" height="24" alt="pixelateit" /></a>

        {
          display === true ? (<div className="navMenuContainer menuDisplay" ref={navMenuContainer}>
            <Link to="/pageUnderDevelopment" ><span>Gallery</span></Link>
            <Link to="/designWork" ><span>Design Work</span></Link>
            <Link to="/pageUnderDevelopment" ><span>Blogs</span></Link>
          </div>) : (
            ""
          )
        }



        <div className={display === true ? ("navMenu menuClose") : ("navMenu")} ref={menuBtn}>
          <div className="lineOne"></div>
          <div className="lineTwo"></div>
          <div className="lineThree"></div>
        </div>
      </div>
    </HeaderSection>
  );
}


const HeaderSection = styled.div`
  width: 100%;
  // height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform ease-out 0.3s;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 999;
  padding: 12px 12px;

  .logo{
    z-index: 9999;
  }




  .navContainer {
    width: 100%;
    max-width: 1400px;
    // height: 70px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    background: rgba(0, 0, 0, 0.90);
    backdrop-filter: blur(8px);
    padding: 16px;

    .navMenuContainer {
      width: 100vw;
      height: 100vh;
      display: none;
      flex-direction: column;
      gap: 0px;
      align-items: center;
      justify-content: center;
      // background: #FF471E;
      background: radial-gradient(207.62% 141.42% at 0% 0%, #373737 0%, #000 100%);
      position: fixed;
      top: -12px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 999;
      opacity: 0;
      transition: all ease-in-out 0.5s;
      pointer-events: none;



      a {
        color: #f2f2f2;
        width: 100%;
        padding: 10px;
        position: relative;
        outline: none;
        pointer-events: visible;
        display: flex;
        align-items: center;
        justify-content: center;


        span{
          max-width: 1400px;
          width: 100%;
          font-family: 'Wix Madefor Display', sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 44px;
          line-height: 44px;
          text-transform: uppercase;
          text-decoration: none;
          text-edge: cap;
          leading-trim: both;
          letter-spacing: -1.8px;
        }
        @media (min-width: 1200px) {
        span{
          font-size: 100px;
          line-height: 100px;
          letter-spacing: -5px;
        }

          padding: 10px 80px;

        }

        ::after {
          content: "";
          width: 100%;
          height: 1px;
          // background: linear-gradient(90deg, #FF471E 0%, rgba(255, 71, 30, 0.00) 100%, rgba(255, 71, 30, 0.00) 100%);
          background: linear-gradient(90deg, rgba(255, 71, 30, 0.00) 0%, #FF471E 100%);
          position: absolute;
          top: 50%;
          left: 0%;
          transform: translateY(-50%);
          opacity: 0;
          z-index: -1;
          transition: all ease-in-out 0.2s;
        }
        :hover {
          ::after {
            opacity: 1;
            // transform: translateY(0px);
            height: 100%;
          }
        }

        
      }
    }

    .menuDisplay{
      opacity: 1;
      display: flex;
    }
    
    .navMenu {
      display: flex;
      flex-direction: column;
      gap: 4px;
      height: 40px;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: transform ease 0.25s;
      z-index: 9999;
      position: relative;



      .lineOne {
        width: 34px;
        height: 10px;
        background: #fff;
        transition: all ease 0.25s;
        opacity: 1;
      }
      .lineTwo {
        width: 54px;
        height: 10px;
        background: #fff;
        transition: all ease 0.25s;
      }
      .lineThree {
        width: 34px;
        height: 10px;
        background: #fff;
        transition: all ease 0.25s;
      }
    }
    .menuClose {
      height: 40px;
      width: 40px;
      .lineOne {
        width: 40px !important;
        transform: rotate(-45deg);
        height: 5px;
        position: absolute;
        top: 50%;
      }
      .lineTwo {
        width: 40px;
        height: 5px;
        position: absolute;
        top: 50%;
      }
      .lineThree{
        width: 40px;
        transform: rotate(45deg);
        height: 5px;
        position: absolute;
        top: 50%;
      }
    }
  }
`;

export default Header;
