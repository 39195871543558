import React from 'react'
import Header from '../Components/Header'
import Footer from '../Components/Footer'
import noise from "../assets/noise.gif"
import styled from 'styled-components'
import PageNotFound from '../Components/PageNotFound'

function Error404() {
    return (
        <>
            <Image />
            <Header />
            <PageNotFound />
            <Footer />

        </>
    )
}

const Image = styled.div`
background-image: url(${noise});
background-size: 70px;
opacity: 0.15;
height: 100%;
width: 100%;
position: fixed;
top: 0;
left: 0;
z-index: 999999999;
pointer-events: none;
`


export default Error404